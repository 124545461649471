import React from "react";
import { graphql } from "gatsby";
import LayoutComponent from "../components/global/layout";
import SEOComponent from "../components/global/seo";
import BlogPostByCategoryComponent from "../components/blog/posts-by-category";
import smoothScrollTo from "gatsby-plugin-smoothscroll-offset";
const { find } = require("lodash");

const ArchivePage = ({ data, pageContext }) => {
  const { allMarkdownRemark } = data;
  
  const categoryPosts = (category) => {
    return allMarkdownRemark.edges.filter(({ node }) => {
      return find(node.frontmatter.categories, function(cat) { return cat.slug === category }) != null
    })
  }

  return (
    <LayoutComponent>
      <SEOComponent title="Archive" 
            metaDescription="Here's a list of all the posts I've written over the years." 
            canonicalUrl="/Blog/Archive"
            shareImageIsLarge={true} />
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            Archive
          </h1>
          <p>Here's a list of all the posts I've written over the years.</p>
        </header>
        <div className="space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
          <div className="flex flex-col pt-8 space-x-2">
            <div className="prose">
              <p>I talk about a wide variety of things, such as:</p>
              <ul className="archive-category-list">
                {pageContext.allCategories.map((cat, index) => (
                  <li key={`cat-${index}`}><a className="archive-category-link" onClick={() => smoothScrollTo(`#${cat.slug}`, 80)}>{cat.name}</a></li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pt-8 pb-8 sm:pt-6 sm:pb-6 max-w-none xl:col-span-2">
            {pageContext.allCategories.map((cat, index) => (
              <BlogPostByCategoryComponent key={`catposts-${index}`} category={cat} posts={categoryPosts(cat.slug)} />
            ))}          
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default ArchivePage

export const query = graphql`
    query blogPostsArchive {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }, filter: {fields: {type: {eq: "post"}}}
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              summary
              date
              categories {
                name
                slug
              }
            }
            timeToRead
          }
        }
      }
    }`